import { getDeviceSDK } from '@/components/device-sdk'
import useEvent from '@/hooks/useEvent'
// @ts-expect-error: missing type declaration
import BridgeV2 from '@akulaku/web-bridge'
import type { FoundationProps } from '@al/security-verification-foundation'
import { Loading } from '@Arui/index'
import _ from 'lodash'
import { useEffect } from 'react'

const log = (...args: any) => console.log('[app端安全组件]: ', ...args)

/**
 * 安全校验app端
 * 这里对齐web端安全校验组件的暴露相应的props
 */
export function SecurityVerificationMobileApp(props: FoundationProps) {
  const doAppVerify = useEvent(async () => {
    const ALDeviceSDK = await getDeviceSDK()
    const smDeviceId = await ALDeviceSDK.getSmDeviceId()

    /**
     * 这里转换web端安全组件的参数到app端需要的参数
     * jsbridge文档
     * @see https://test-ec-mall.akulaku.com/jsbridge/#/detail/checkRisk
     * 接口文档
     * @see http://testyapi.akulaku.com/project/1045/interface/api/115008
     */
    const appParams: any = {
      ...props.extraParams,
      // 目前只有在app端需要传递smDeviceId
      smDeviceId,
      type: props.bizType,
      amount: props.extraParams?.amount,
      isSecretPay: props.extraParams?.isSecretPay,
      orderId: props.extraParams?.orderId,
      clickPayTime: props.extraParams?.clickPayTime,
      downPayment: props.extraParams?.downPayment,
      payPwdBusinessId: props.extraParams?.payPwdBusinessId,
      fullProductId: props.extraParams?.fullProductId,
    }
    const nonNullableParams = _.omitBy(appParams, _.isNil)
    log('开始安全校验', nonNullableParams)

    Loading.hide()

    BridgeV2.checkRisk(nonNullableParams, (res: any) => {
      log('校验结果', res)
      if (res.success) {
        props.onSuccess?.(res.data)
        log('校验成功')
      } else {
        props.onRejected?.(true)
        log('校验失败')
      }
      props.onClose?.()
    })
  })
  useEffect(() => {
    doAppVerify()
  }, [doAppVerify])

  return null
}
