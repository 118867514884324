import React, { useState, useMemo, useEffect } from 'react'
import { useModal, create, show } from '@ebay/nice-modal-react'
import { Modal } from 'antd-mobile'
import axios from 'axios'
import { Translate } from '@common/Basic/translate'
import Logger from '@/project/openPay @/config/logger'
import saConfig from './sa.config'
import styles from './style.scss'

// app换绑指引
const AppRebindGuide = ({ onClose, phoneNumber, merchantName, bizType }) => {
  const { translate: t } = Translate.getInstance()
  const [config, setConfig] = useState({})
  const logger = Logger.getInstance()
  const getConfig = async () => {
    const openpayBid = 1017
    const params = {
      bidPairList: [
        {
          bid: openpayBid,
          bidVersion: 1,
        },
      ],
      platform: 3,
      appVersion: 0,
      regionId: 1,
      appId: 1,
    }
    const {
      data: { data, success },
    } = await axios.post(
      '/capi/app-config-service/public/list-config-info',
      params,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    if (success && data) {
      // 配置中心的配置一定是json格式
      const config = JSON.parse(
        data.find((item) => item.bid === openpayBid).content,
      )
      setConfig(config)
    }
  }

  const saParams =
    bizType === 204
      ? {
          pageId: 'openpay18',
          pageName: 'new user register page',
        }
      : {
          pageId: 'openpay01',
          pageName: 'opaypay-login page',
        }

  useEffect(() => {
    logger.popView(saParams)
    getConfig()
  }, [])

  const data = useMemo(() => {
    return config.securityVerificationConfig
      ? config.securityVerificationConfig
      : {}
  }, [config])

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <p className={styles.title}>{t('Sorry Login failed')}</p>
        <p className={styles.desc}>
          {t(
            'Your Akulaku PayLater account ${phoneNumber} has already connected to another account of the current merchant Please disconnect first',
            { phoneNumber, merchantName },
          )}
        </p>
        {data.serviceTel && (
          <div className={styles.sub_title}>
            {t('1 Contact customer service to disconnect the account')}{' '}
            <a
              href={`tel:${data.serviceTel}`}
              onClick={() => {
                logger.popClick({
                  ...saParams,
                  ...saConfig.click_tel,
                })
              }}
            >
              {data.serviceTel}
            </a>
          </div>
        )}
        {data.rebindGuideImg && (
          <div className={styles.sub_title}>
            {t('2 Disconnect the account in the Akulaku app')}
          </div>
        )}
        <div className={styles.steps}>
          <img src={data.rebindGuideImg} />
        </div>
      </div>

      <div className={styles.footer}>
        <div
          className={'aku-btn-primary'}
          onClick={() => {
            logger.popClick({
              ...saParams,
              ...saConfig.i_know,
            })
            onClose()
          }}
        >
          {t('Got It')}
        </div>
      </div>
    </div>
  )
}

const RebindInAppPop = create((props) => {
  const modal = useModal()
  return (
    <Modal
      popup
      className={styles.modal}
      visible={modal.visible}
      animationType="slide-up"
    >
      <AppRebindGuide
        onClose={modal.hide}
        bizType={props.bizType}
        phoneNumber={props.phoneNumber}
        merchantName={props.merchantName}
      />
    </Modal>
  )
})

export default {
  show(props) {
    show(RebindInAppPop, props)
  },
}
