import { useBasicContext } from '@common/Basic'
import _ from 'lodash'
import { FC } from 'react'
import styles from './index.scss'
import { Translate } from '@common/Basic/translate'
import { classNames } from '@/vendor/common @/helpers'

export const Footer: FC = (props: any) => {
  const { translate } = Translate.getInstance()

  return (
    <div className={classNames(styles.footer, props?.className)}>
      <div className={styles.text}>
        {translate(
          'Powered by AFI, a finance company registered & supervised by Financial Services Authority of Indonesia (OJK)',
        )}
      </div>
      {props.children}
    </div>
  )
}
