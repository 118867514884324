export default {
  // 注册页点击手机输入框
  register_phone_num: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'openpay180103',
    Aku_element_name: 'input phone',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '03',
  },
  // 注册页点击发送验证码
  register_sms_click: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'openpay180104',
    Aku_element_name: 'send',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '04',
  },
  register_otp_click: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'openpay180106',
    Aku_element_name: 'input code',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '06',
  },
  // 注册页点击注册
  register_click_register: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'openpay180107',
    Aku_element_name: 'register',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '07',
  },
  // 安全组件
  register_security_check: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'openpay180108',
    Aku_element_name: 'register-operation',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '08',
  },
  // 点击协议
  register_click_agreement: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'openpay180109',
    Aku_element_name: 'agreement',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '09',
  },
  // 点击已有账号 去登录
  register_go_login_click: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'openpay180110',
    Aku_element_name: 'login',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '10',
  },
  // 老客无法领取弹窗
  register_old_user_pop_view: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_pop_id: 'pop90020',
    Aku_pop_name: 'unable to receive',
  },
  // 老客无法领取弹窗确认按钮
  register_old_user_pop_click: {
    Aku_page_id: 'openpay18',
    Aku_page_name: 'new user register page',
    Aku_element_id: 'pop9002001',
    Aku_element_name: 'comfirm',
    Aku_pop_id: 'pop90020',
    Aku_pop_name: 'unable to receive',
  },
  // 设置密码提交
  register_sumbit_click: {
    Aku_page_id: 'openpay19',
    Aku_page_name: 'set password',
    Aku_element_id: 'openpay190104',
    Aku_element_name: 'comfirm ',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'set',
    Aku_position_id: '04',
  },
  // openpay注册登录页-账号密码页
  page_view: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
  },
  // 切换登录方式（手机号登录、账号登录）
  switch_login_method: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010101',
    Aku_element_name: 'switch login method',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '01',
  },
  // 点击手机号输入框
  click_phone_num: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010102',
    Aku_element_name: 'click phone num box',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '02',
  },
  // 输入手机号
  input_phone_num: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010103',
    Aku_element_name: 'input phone num',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '03',
  },
  // 点击密码、验证码输入框
  click_password_box: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010104',
    Aku_element_name: 'click password box',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '04',
  },
  // 输入密码、验证码
  input_password: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010105',
    Aku_element_name: 'input password',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '05',
  },
  // 账号登录-点击忘记密码
  forget_password: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010107',
    Aku_element_name: 'forget password',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '07',
  },
  // 短信登录-点击发送验证码
  send: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010108',
    Aku_element_name: 'send',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '08',
  },
  login: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010106',
    Aku_element_name: 'click login',
  },
  // 短信登录-点击协议
  click_agreement: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010109',
    Aku_element_name: 'click agreement',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '09',
  },
  // 设置密码弹框-曝光
  set_password_popup: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010201',
    Aku_element_name: 'set password-popup',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'set password',
    Aku_position_id: '01',
  },
  // 设置密码输入框点击
  click_set_password_box: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010202',
    Aku_element_name: 'click set password box',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'set password',
    Aku_position_id: '02',
  },
  // 设置密码输入
  input_set_password: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010203',
    Aku_element_name: 'input password',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'set password',
    Aku_position_id: '03',
  },
  // 确认密码输入框点击
  click_password_again_box: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010204',
    Aku_element_name: 'click password-again box',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'set password',
    Aku_position_id: '04',
  },
  // 确认密码输入
  input_password_again: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010205',
    Aku_element_name: 'input  password-again',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'set password',
    Aku_position_id: '05',
  },
  // 设置密码页点击确认
  confirm: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010206',
    Aku_element_name: 'confirm',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'set password',
    Aku_position_id: '06',
  },
  set_password_click: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010113',
    Aku_element_name: 'set password',
  },
  // 忘记密码弹框展示-曝光
  forget_password_popup: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010301',
    Aku_element_name: 'forget password-popup',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'forget password',
    Aku_position_id: '01',
  },
  // 忘记密码弹框点击关闭
  close: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010302',
    Aku_element_name: 'close',
    Aku_moudle_id: '03',
    Aku_moudle_name: 'forget password',
    Aku_position_id: '02',
  },
  // 登录页新增分期信息选项
  click_installment: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010110',
    Aku_element_name: 'click installment',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '10',
  },
  // 新增分期信息弹窗-曝光
  installment_popup: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010401',
    Aku_element_name: 'installment-popup',
    Aku_moudle_id: '04',
    Aku_moudle_name: 'installment',
    Aku_position_id: '01',
  },
  // 分期信息弹窗选项点击（记录点击的是哪个分期期数的选项）
  click_installment_option: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010402',
    Aku_element_name: 'click installment option',
    Aku_moudle_id: '04',
    Aku_moudle_name: 'installment',
    Aku_position_id: '02',
  },
  // 登录页活动图点击
  click_banner: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010112',
    Aku_element_name: 'banner',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '11',
  },
  // 登录页活动图点击
  click_banner_jump: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010111',
    Aku_element_name: 'banner',
  },
  // open登录注册页-短信登录
  page_view_login: {
    Aku_page_id: 'openpay07',
    Aku_page_name: 'opaypay-login page',
  },
  // 点击切换tab(账号登录、手机号验证)
  click_tab: {
    Aku_page_id: 'openpay07',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay070101',
    Aku_element_name: 'tab',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '01',
  },
  // 发送
  click_send: {
    Aku_page_id: 'openpay07',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay070102',
    Aku_element_name: 'send',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '02',
  },
  // 登录
  click_login: {
    Aku_page_id: 'openpay07',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay070103',
    Aku_element_name: 'login',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '03',
  },
  // 没有账号去注册
  click_go_to_register: {
    Aku_page_id: 'openpay07',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay070104',
    Aku_element_name: 'go to register',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '04',
  },
  // 忘记密码按钮
  click_forget_password: {
    Aku_page_id: 'openpay07',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay070105',
    Aku_element_name: 'forgot password',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '05',
  },
  // open登录注册页-手机号注册
  page_view_register: {
    Aku_page_id: 'openpay08',
    Aku_page_name: 'opaypay-register page',
  },
  // 短信验证码注册发送
  click_register_send: {
    Aku_page_id: 'openpay08',
    Aku_page_name: 'opaypay-register page',
    Aku_element_id: 'openpay080101',
    Aku_element_name: 'send',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '01',
  },
  // 注册按钮
  click_register_register: {
    Aku_page_id: 'openpay08',
    Aku_page_name: 'opaypay-register page',
    Aku_element_id: 'openpay080102',
    Aku_element_name: 'register',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '02',
  },
  // 已有账号去登录按钮
  click_register_login: {
    Aku_page_id: 'openpay08',
    Aku_page_name: 'opaypay-register page',
    Aku_element_id: 'openpay080103',
    Aku_element_name: 'login',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register',
    Aku_position_id: '03',
  },
  // 绑定账号按钮（打开）
  click_binding_open: {
    Aku_page_id: 'openpay20',
    Aku_page_name: 'tokopeida login page',
    Aku_element_id: 'openpay2001',
    Aku_element_name: 'open',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '01',
  },
  // 绑定账号按钮（关闭）
  click_binding_close: {
    Aku_page_id: 'openpay20',
    Aku_page_name: 'tokopeida login page',
    Aku_element_id: 'openpay2002',
    Aku_element_name: 'close',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '02',
  },
  // 绑定按钮（点击？查看详情）
  click_binding_detail: {
    Aku_page_id: 'openpay20',
    Aku_page_name: 'tokopeida login page',
    Aku_element_id: 'openpay2003',
    Aku_element_name: 'detail',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '03',
  },
  // 查看详情弹窗展示
  click_binding_modal_view: {
    Aku_page_id: 'openpay20',
    Aku_page_name: 'tokopeida login page',
    Aku_element_id: 'openpay2001',
    Aku_element_name: 'pop-exposure',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'pop',
    Aku_position_id: '01',
  },
  // 点击关闭
  click_binding_modal_close: {
    Aku_page_id: 'openpay20',
    Aku_page_name: 'tokopeida login page',
    Aku_element_id: 'openpay2002',
    Aku_element_name: 'close',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'pop',
    Aku_position_id: '02',
  },
  // 点击我知道了
  click_binding_modal_ok: {
    Aku_page_id: 'openpay20',
    Aku_page_name: 'tokopeida login page',
    Aku_element_id: 'openpay2003',
    Aku_element_name: 'get',
    Aku_moudle_id: '02',
    Aku_moudle_name: 'pop',
    Aku_position_id: '03',
  },
  // 展开分期计划 openpay01
  expand_openpay010116: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010116',
    Aku_element_name: 'expand',
    Aku_moudle_id: '01',
    Aku_moudle_name: '',
    Aku_position_id: '16',
  },
  // 收起分期计划 openpay01
  collapse_openpay010117: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010117',
    Aku_element_name: 'collapse',
    Aku_moudle_id: '01',
    Aku_moudle_name: '',
    Aku_position_id: '17',
  },
  // 防刷安全校验
  otp_security_check: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010121',
    Aku_element_name: 'send operation',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '21',
  },
  // 登录安全校验
  login_security_check: {
    Aku_page_id: 'openpay01',
    Aku_page_name: 'opaypay-login page',
    Aku_element_id: 'openpay010120',
    Aku_element_name: 'operation',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'login',
    Aku_position_id: '20',
  },
}
