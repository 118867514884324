export default {
  // 点击我知道了
  i_know: {
    Aku_element_id: 'pop9001101',
    Aku_element_name: 'i see',
  },
  // 点击客服电话
  click_tel: {
    Aku_element_id: 'pop9001102',
    Aku_element_name: 'call',
  },
}
