import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { helpers } from '@common'
import { Translate } from '../../config/translate'

import styles from './style.m.scss'
const _className = helpers.classNames.react(styles)

export class FormInput extends PureComponent {
  constructor() {
    super()

    this.state = {
      value: null,
      errMsg: '',
      openEyeStatus: false, // 针对密码输入框的状态
      focusStatus: false, // 聚焦状态
    }

    this.translate = Translate.getInstance().translate
    this.setErrorMsgDelay = this.throttle(this.setErrorMsg, 800)
    this.inputRef = React.createRef()
  }

  rules = {
    phone(str, countryCode = 'ID') {
      const regID = /^08[0-9]{8,11}$|^8[0-9]{8,11}$/ //印尼手机号
      const regMY = /^01[0-9]{8,9}$|^1[0-9]{8,9}$/ //马来手机号
      const regPH = /^0[89][0-9]{8,9}$|^[89][0-9]{8,9}$/ //菲律宾手机号
      const regVN =
        /^0((1[0-9])|[35789])[0-9]{8}$|^08[0-9]{7}$|^((1[0-9])|[35789])[0-9]{8}$|^8[0-9]{7}$/ //越南手机号

      let reg
      switch (countryCode) {
        case 'ID':
          reg = regID
          break
        case 'PH':
          reg = regPH
          break
        case 'VN':
          reg = regVN
          break
        case 'MY':
          reg = regMY
          break
        default:
          reg = regID
          break
      }

      if (!reg.test(String(str))) {
        return this.translate('请输入正确的手机号码')
      } else {
        return false
      }
    },
    password(str) {
      const reg = /^\S{8,16}$/
      if (!reg.test(str)) {
        return this.translate('请输入8-16字符')
      } else {
        return false
      }
    },
    code(str) {
      const reg = /^[0-9]{5}$/

      if (!reg.test(String(str))) {
        return this.translate('验证码必须为5位数字')
      } else {
        return false
      }
    },
    creditCard(str) {
      const reg = /^[0-9]{16}$/

      if (!reg.test(String(str))) {
        return this.translate('信用卡号必须为16位数字')
      } else {
        return false
      }
    },
    number(str, range = []) {
      const reg = /^[0-9]+$/
      let min = range[0],
        max = range[1]

      if (!max) {
        max = min
      }
      str = String(str)

      if (!reg.test(str)) {
        return this.translate('请输入数字')
      } else if (str.length > max || str.length < min) {
        if (min === max) {
          return this.translate('请输入${min}位数字', { min })
        } else {
          return this.translate('请输入${min} - ${max}范围的数字', { min, max })
        }
      } else {
        return false
      }
    },
    couponCode(str) {
      const reg = /^[0-9a-zA-Z]{0,30}$/
      if (!reg.test(str)) {
        return this.translate('无效的兑换码')
      } else {
        return ''
      }
    },
  }

  throttle(method, delay) {
    let timer = null
    return function (...argv) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        method.apply(this, argv)
      }, delay)
    }
  }

  //格式化输入
  format(event, val) {
    const { maxLength, format, zeroPadding } = this.props
    if (maxLength && val.length > +maxLength) {
      val = val.slice(0, +maxLength)
      event.target.value = val
    }

    format && (val = format(val))
    return val
  }

  clearAll = () => {
    this.inputRef.current && this.inputRef.current.focus()
    const { onChange, onControl, name, onClear } = this.props

    onControl &&
      onControl({
        target: {
          name,
          value: '',
        },
      })
    onChange && onChange('')
    onClear && onClear()

    this.setState({ value: '' })
  }

  handleChange = (event) => {
    const { onControl, onChange } = this.props
    const { focusStatus } = this.state
    const value = this.format(event, event.target.value)
    if (!focusStatus) this.setState({ focusStatus: true })

    onControl && onControl(event)
    onChange && onChange(value)

    this.setState({ value })
  }

  handleFocus = (event) => {
    const { onFocus } = this.props
    this.setState({
      focusStatus: true,
    })

    onFocus && onFocus(event)
    this.setErrorMsg()
  }

  handleBlur = (event) => {
    const { onBlur, onControl, name, zeroPadding } = this.props
    // 去空
    let value = this.state.value?.trim()
    // 补零
    if (zeroPadding && value) {
      if (value?.trim()[0] !== '0') {
        value = 0 + value?.trim()
      }
    }
    // 同步value
    this.setState({ value })
    onControl &&
      onControl({
        target: {
          name,
          value,
        },
      })
    const errMsg = this.validate(value, true) //验证成功则返回false，验证失败则返回errMsg
    if (errMsg) {
      this.props.onError?.(errMsg)
    } else {
      this.props.onSuccess?.(value)
    }
    this.setState({
      focusStatus: false,
    })

    onBlur && onBlur(event, value)
  }

  validate = (val, delay) => {
    const { countryCode, required, validation } = this.props
    const { phone, password, code, creditCard, number, couponCode } = this.rules
    let errMsg = ''

    //判断不能为空
    if (required && (val === '' || val === null || val === undefined)) {
      errMsg = this.translate('该项不能为空')
      delay ? this.setErrorMsgDelay(errMsg) : this.setErrorMsg(errMsg)
      return errMsg
    }

    //判断基本验证规则
    if (validation) {
      const type = validation.type
      switch (type) {
        case 'phone':
          errMsg = phone.call(this, val, countryCode)
          break
        case 'password':
          errMsg = password.call(this, val)
          break
        case 'code':
          errMsg = code.call(this, val)
          break
        case 'creditCard':
          errMsg = creditCard.call(this, val)
          break
        case 'number':
          const range = validation.range
          errMsg = number.call(this, val, range)
          break
        case 'couponCode':
          errMsg = couponCode.call(this, val)
          break
        default:
          break
      }
    }

    if (errMsg) {
      delay ? this.setErrorMsgDelay(errMsg) : this.setErrorMsg(errMsg)
      return errMsg
    } else {
      // this.setErrorMsg('')
      delay ? this.setErrorMsgDelay('') : this.setErrorMsg('')
      return false
    }
  }

  setErrorMsg(errMsg = '') {
    this.setState({ errMsg })
  }

  componentDidMount() {
    const { onRegister, value, name, onRef, defaultValue } = this.props
    this.name = name

    onRef && onRef(this)
    if (value || defaultValue) {
      this.setState({ value: value ?? defaultValue })
    }
    onRegister && onRegister(this)
    onRef && onRef(this)
  }

  // 获取input 的类型 小额免密需求加入的
  getInputType = () => {
    const { type, hasEye } = this.props
    const { openEyeStatus } = this.state
    // 如果是密码框 并且需要展示眼睛
    if (type === 'password' && hasEye && openEyeStatus) {
      return 'text'
    }
    return type
  }

  // 切换眼睛状态
  toggleEyeStatus = () => {
    this.inputRef.current && this.inputRef.current.focus()
    this.setState({
      openEyeStatus: !this.state.openEyeStatus,
    })
  }

  // 检查数据是否为空
  checkValue = (val) => {
    return val !== null && val !== undefined && val !== ''
  }

  /**
   * 判断是否能出现清空按钮
   * 聚焦清空下 有内容出现
   * 失焦不出现
   * */
  checkClearVisible = () => {
    const { value, focusStatus } = this.state

    return focusStatus && this.checkValue(value)
  }

  render() {
    const {
      className,
      name,
      placeholder,
      hasTips,
      maxLength,
      disabled,
      autoFocus,
      autocomplete,
      defaultValue,
      hasClearIcon = false,
      hasEye,
    } = this.props
    const { errMsg, value } = this.state
    const inputType = this.getInputType()

    return (
      <div
        {..._className('input-group', {
          'text-error': hasTips && errMsg,
          one_icon: hasEye || hasClearIcon,
          two_icon: hasEye && hasClearIcon,
        })}
      >
        <input
          className={className}
          type={inputType}
          name={name}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          value={value !== null ? value : defaultValue}
          maxLength={maxLength}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={this.inputRef}
          autoComplete={autocomplete}
        />
        <span {..._className('icon_area')}>
          {/* hasClearIcon 控制是否需要渲染这个节点  this.checkClearVisible() 控制是否能看到这个Icon */}
          {hasClearIcon ? (
            <img
              src={require('./clear.png')}
              onTouchStart={this.clearAll}
              {..._className('icon_clear icon_item', {
                icon_opacity: !this.checkClearVisible(),
              })}
            />
          ) : null}
          {hasEye ? (
            <img
              src={
                this.state.openEyeStatus
                  ? require('./openeye.png')
                  : require('./eyeclosed.png')
              }
              onClick={this.toggleEyeStatus}
              {..._className('icon_eye icon_item')}
            />
          ) : null}
        </span>
        <p>{hasTips ? errMsg : ''}</p>
      </div>
    )
  }
}

FormInput.propTypes = {
  type: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  onRegister: PropTypes.func.isRequired,
  onControl: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  validation: PropTypes.object,
}
