import { dynamic } from 'umi'

import { bridge } from '~/common/bridge'
import { SecurityVerificationMobileApp } from '@/components/SecurityVerification/index.app'

export const SecurityVerificationMobile = bridge.isAkulaku
  ? SecurityVerificationMobileApp
  : dynamic(() => {
      // 安全组件需要用到的样式文件
      import('@al/security-verification-style/dist/style.css')
      import('@al/security-verification-mobile/dist/style.css')
      return import(
        /* webpackPrefetch: true */ '@al/security-verification-mobile'
      )
    })
