import { Toast } from 'antd-mobile'
import { Translate } from '@common/Basic/translate'
import { history } from 'umi'
import RebindInAppPop from '../RebindInAppPop'

export default function (phoneNumber, bizType, extra) {
  const { translate: t } = Translate.getInstance()
  // 当前操作存在风险，绑定失败，请先下载Akulaku APP，在app内完成绑定
  if (extra?.errCode === '132132500036') {
    Toast.info(
      t('当前操作存在风险，绑定失败，请先下载Akulaku APP，在app内完成绑定'),
      3,
      () => {
        history.push('/download-app?newUi=1')
      },
    )
    return true
  }
  // 换绑有风险，需要去app内解绑
  if (extra?.errCode === '132132500037') {
    RebindInAppPop.show({
      bizType,
      phoneNumber,
      merchantName: sessionStorage.getItem('op_merchant_name') || '',
    })
    return true
  }
  return false
}
