/** 按钮活动标签 */

import { helpers } from '@common'
import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

export default ({ children = '' }) => {
  if (!children) return null
  return (
    <div {...className(styles.tag)}>
      <img {...className(styles.icon)} src={require('./images/icon.png')} />
      <div {...className(styles.text)}>{children}</div>
      <img {...className(styles.suffix)} src={require('./images/suffix.png')} />
    </div>
  )
}
