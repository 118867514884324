import React, { useEffect, useState } from 'react'
import { create, useModal } from '@ebay/nice-modal-react'
import JsCookie from 'js-cookie'
import { helpers } from '@common'
import { getSmDeviceData, getDeviceUUID } from '@/components/device-sdk'
import checkRejectReason from './checkRejectReason'
import { SLIDER_CONFIG, FACE_CONFIG } from '~/common/macro'

const isPc = () => window.location.href.includes('/pc/')
const SecurityVerification = isPc()
  ? require('../SecurityVerification/index.pc').SecurityVerificationPc
  : require('../SecurityVerification/index').SecurityVerificationMobile

export default create((props) => {
  const modal = useModal()
  const [deviceId] = useState(helpers.storage.get('deviceId') || 'unknown')
  const [uuid, setUuid] = useState(
    helpers.storage.getSession('deviceData')?.uuid || 'unknown',
  )

  useEffect(() => {
    getDeviceUUID().then((uuid) => {
      if (uuid) setUuid(uuid)
    })
  }, [])

  const handleSuccess = (data) => {
    props.onSuccess && props.onSuccess(data)
    modal.resolve(data)
    modal.remove()
  }

  const handleClose = (error) => {
    props.onClose && props.onClose()
    modal.resolve({ error })
    modal.remove()
  }

  const handleRejected = (retry, extra) => {
    // 在reject分支，只有业务处理的两种特殊错误码需要关闭安全组件，其他错误码由安全组件展示错误信息
    const shouldClose = checkRejectReason(
      props?.extraParams?.phone,
      props?.bizType,
      extra,
    )
    props.onRejected && props.onRejected(retry, extra)
    modal.resolve({})
    if (shouldClose) modal.remove()
  }

  if (!deviceId) return null

  const languageCode = JsCookie.get('languageCode')

  return (
    <SecurityVerification
      deviceId={deviceId}
      deviceUuid={uuid}
      hideInitialLoading={true}
      visible={true}
      locale={languageCode ? languageCode.toLowerCase() : 'in'}
      env={helpers.getRuntimeEnv()}
      saParams={{
        pageId: '',
        pageName: '',
      }}
      countryCode={JsCookie.get('countryCode') || 'ID'}
      languageCode={languageCode ? languageCode.toLowerCase() : 'in'}
      {...props}
      onSuccess={handleSuccess}
      onClose={handleClose}
      onRejected={handleRejected}
      // onRequestError={handleClose}
      getSmDeviceData={getSmDeviceData}
      sliderData={SLIDER_CONFIG}
      faceOptions={FACE_CONFIG}
    />
  )
})
